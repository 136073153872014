@import './variables';

@mixin verticalFlexGap($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin imageRoundedContainer($width, $height: $width, $radius: 8px) {
  position: relative;
  width: $width;
  height: $height;
  border-radius: $radius;
  overflow: hidden;
}

@mixin bottomDivider($offset: 1rem, $padding: $offset) {
  padding-bottom: $padding;
  border-bottom: 1px solid $secondary-color-03;
  margin-bottom: $offset;
}

@mixin topDivider($offset: 1rem) {
  padding-top: $offset;
  border-top: 1px solid $secondary-color-03;
  margin-top: $offset;
}

@mixin limitTextLines($linesCount: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $linesCount;
  line-clamp: $linesCount;
  -webkit-box-orient: vertical;

  &:hover {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
