@import "../../theme/variables";
@import "../../theme/snippets";

.container {
  @extend %centred-content;
  margin: 16px 0;

  p {
    margin: 0;
    padding: 0 12px;
    color: $secondary-color-00;
  }
}

.line {
  height: 1px;
  width: 100%;
  background-color: $secondary-color-03;
  margin: 0;
}
