@import '../../theme/variables';

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  .cover {
    background-color: $neutral-white;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  p {
    z-index: 9;
    position: absolute;
    bottom: 0;
    width: calc(100% - 2rem);
    margin: 1rem;
    padding: 0.5rem;
    background-color: $neutral-white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
}
