@import '../../theme/variables';
@import '../../theme/snippets';

$brandWidth: 204px;
$brandHeight: 88px;
$brandWidthPadding: 172px;
$brandHeightPadding: 56px;
$smallBrandWidth: 131px;
$smallBrandHeight: 56px;
$smallBrandWidthPadding: 99px;
$smallBrandHeightPadding: 40px;

.brandsList {
  width: 100%;
  gap: 1rem;

  &.commonList {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: start;
  }

  &.halfedList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &.inlineList {
    display: grid;
    grid-auto-columns: max-content;
    grid-template-rows: 1fr;
    width: 100%;
    overflow-x: auto;
    padding: 0 48px;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .brand {
      grid-row: 1;
      grid-column: auto;
    }
  }

  &.inlineFeedList {
    @extend %hidden-scroll;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    .brand {
      width: 200px;
      height: 125px;
      flex-shrink: 0;
    }
  }
}

.svgPadding {
  min-width: $brandWidthPadding;
  max-width: $brandWidthPadding;
  padding-top: $brandHeightPadding;
  position: relative;
}

.brand {
  min-width: $brandWidth;
  max-width: $brandWidth;
  padding-top: $brandHeight;
  border: 2px solid $secondary-color-02;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s;
  position: relative;
  opacity: 0.9;

  &.withOpacity {
    opacity: 0.2;
  }

  &.halfed {
    width: 47%;
    padding-top: 23%;
  }

  &:hover {
    opacity: 1;
    border: 2px solid $secondary-color-03;
  }

  &.lightBorder {
    border: 2px solid $secondary-color-03;
  }

  &.activeItem {
    border: 2px solid $primary-color-00;
    box-shadow: 0 0 0 1px #6cc4d4;
    opacity: 1;
    box-sizing: border-box;
  }

  h3 {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0.2rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: $neutral-white;
    font-weight: 500;
    z-index: 2;

    &:hover {
      color: $neutral-white;
    }
  }
}

@media (max-width: $desktop-width) {
  .brand.halfed {
    width: 100%;
    padding-top: 46%;
  }
}

@media (max-width: $tablets-width) {
  .brandsList.halfedList {
    display: grid;
    grid-auto-flow: column;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: start;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .brandsList.inlineList {
    padding: 0 16px;
  }

  .brand.halfed {
    width: $brandWidth;
    padding-top: $brandHeight;
  }

  .brand.inline {
    min-width: $smallBrandWidth;
    max-width: $smallBrandWidth;
    padding-top: $smallBrandHeight;
  }

  .brandsList.inlineFeedList {
    height: 128px;

    .brand {
      width: 132px;
      height: 80px;
      margin: 16px 0;

      h3 {
        padding: 5px 12px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

@media (max-width: $under-tablet-width) {
  .brandsList {
    gap: 0.5rem;
  }
  .brandsList.inlineFeedList {
    height: 104px;

    padding-bottom: 0;
    margin-bottom: 0;

    .brand {
      padding-top: 76px;
      &:first-child {
        margin-left: 1rem;
      }

      &:last-child {
        margin-right: 1rem;
      }

      span {
        height: 80px !important;
        width: 128px !important;
      }
    }
  }
  .smallerMobile {
    min-width: $smallBrandWidth;
    max-width: $smallBrandWidth;
    padding-top: $smallBrandHeight;
  }

  .svgPadding {
    min-width: $smallBrandWidthPadding;
    max-width: $smallBrandWidthPadding;
    padding-top: $smallBrandHeightPadding;
    position: relative;
  }
}
