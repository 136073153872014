@import '../../theme/variables';

.listSort {
  display: flex;
  align-items: center;

  .listSortLabel {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $secondary-color-02;
    margin-right: 8px;
  }

  .listSortDropdown {
    position: relative;
    width: 115px;
    padding-left: 8px;

    .listSortDropdownValue {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $secondary-color-00;
      cursor: pointer;

      .listSortDropdownValueIcon {
        width: 24px;
        height: 24px;
        margin-left: 8px;
      }
    }

    .listSortDropdownOptions {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      width: 100%;
      background-color: #fff;
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .listSortDropdownOptionsItem {
        font-size: 1rem;
        cursor: pointer;
        color: $secondary-color-00;
        &:hover {
          color: $primary-color-01;
        }
      }
    }
  }
}
