@import '../../theme/variables';
@import '../../theme/snippets';

.container {
  width: 318px;

  .filtersButton {
    display: none;

    .filtersButtonButton {
      padding: 10px;
    }
  }
}

.backdrop {
  width: 100%;
}

.wrapper {
  width: 318px;
  background-color: #fff;

  .modalHeader {
    display: none;
  }
}

.sticky {
  padding-bottom: 24px;
  overflow-y: scroll;
  max-height: 80vh;
  position: fixed;
  top: 160px;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.filter {
  width: 100%;
  border-bottom: 1px solid $secondary-color-03;
  margin-bottom: 16px;
  padding-bottom: 16px;

  &.filtersList {
    min-height: 66px;
    padding-bottom: 24px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    p {
      font-weight: 500;
    }

    .headerIcon {
      height: 24px;
      transition: 0.15s ease-in;
    }

    &.collapsed {
      .headerIcon {
        transform: rotate(-180deg);
      }
    }
  }

  .body {
    margin-top: 16px;
    height: unset;
    max-height: 426px;
    overflow: auto;

    &.collapsed {
      overflow: hidden;
      margin-top: 0;
      height: 0;
    }

    &.withDropdown {
      overflow: unset;
    }
  }
}

.categoriesFilter {
  .categoriesContainer {
    .categoryText {
      cursor: pointer;
      margin-top: 0.5rem;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        color: $primary-color-01;
        cursor: default;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .categoriesChildren {
      padding-left: 7px;
    }
  }

  .categoriesSearch {
    margin-bottom: 1rem;

    input {
      max-width: 316px;
      width: 100%;
    }
  }

  .categoriesChildren {
    max-height: 280px;
    overflow-y: auto;
    -ms-overflow-style: none;
  }

  .showMore {
    margin-top: 12px;
    color: $primary-color-00;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;

    &:hover {
      color: $primary-color-01;
    }
  }
}

.searchableListFilter {
  width: 100%;

  .searchableInputWrapper {
    position: relative;
    margin-bottom: 16px;

    .searchableInputIcon {
      position: absolute;
      left: 16px;
      top: 11px;
      z-index: 1;
    }

    .searchableInput {
      padding: 12px 36px 12px 48px;
      color: $secondary-color-02;
      width: 100%;
      height: 48px;
      border-radius: 8px;
      border: 1px solid $secondary-color-03;
      font-size: 16px;
      position: relative;
      &::placeholder {
        color: $secondary-color-02;
      }
      &:focus,
      &:focus-visible {
        border: 2px solid $primary-color-00;
        outline: none;
      }
    }
  }

  .searchableList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 224px;
    overflow-y: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .searchableListItem {
      width: 100%;
      cursor: pointer;
      display: flex;

      .listItemCheckbox {
        border: 1px solid $secondary-color-03;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: inherit;
        transition: all 0.2s ease;
        margin-right: 8px;
        position: relative;
      }

      &.checked {
        .listItemCheckbox {
          border-color: $primary-color-00;
          background-color: $primary-color-00;

          &:after {
            content: '';
            position: absolute;
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
            border-radius: 0;
            width: 5px;
            height: 10px;
            transform: rotate(45deg);
            left: 7px;
            top: 3px;
          }
        }
      }

      span {
        margin-left: 4px;
        color: $secondary-color-03;
      }
    }
  }
}

.colorsFilter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .colorsFilterItem {
    width: 32px;
    height: 32px;
    border: 1px solid #e9edef;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;

    &.active {
      border-color: $primary-color-00;
    }

    & > div {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}

.sizesFilter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .sizesFilterItem {
    border: 1px solid $secondary-color-03;
    border-radius: 4px;
    padding: 6px 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &.active {
      border-color: $primary-color-00;
    }
  }
}

.priceFilter {
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 48px;

  .priceInputWrapper {
    flex: 1;
    position: relative;

    .priceInput {
      padding: 20px 16px 4px 30px;
      color: $secondary-color-00;
      width: 100%;
      border-radius: 8px;
      border: 1px solid $secondary-color-03;
      font-size: 16px;
      line-height: 24px;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus,
      &:focus-visible {
        border: 1px solid $primary-color-00;
        outline: none;
      }
    }

    .priceInputCurrency {
      color: $secondary-color-00;
      position: absolute;
      bottom: 5px;
      left: 16px;
      font-size: 16px;
      line-height: 24px;
      opacity: 1;
    }

    .priceInputPlaceholder {
      color: $secondary-color-02;
      font-size: 12px;
      line-height: 16px;
      position: absolute;
      top: 4px;
      left: 16px;
    }

    .priceInputButton {
      @extend %centred-content;
      height: 100%;
      padding: 0 16px;
      border-radius: 8px;
      border: 1px solid $secondary-color-03;
      cursor: pointer;

      &:hover {
        border: 1px solid $secondary-color-02;
      }
      &:active {
        background-color: $neutral-gray;
      }
    }
  }
}

.locationFilter {
  width: 100%;

  .cityInput {
    padding: 12px 16px;
    color: $secondary-color-02;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $secondary-color-03;
    font-size: 16px;
    line-height: 24px;
    &::placeholder {
      color: $secondary-color-02;
    }
    &:focus,
    &:focus-visible {
      border: 1px solid $primary-color-00;
      outline: none;
    }
  }
}

.filtersList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .filtersListItem {
    display: flex;
    align-items: center;
    padding: 10px 4px;
    background-color: $neutral-gray;
    border-radius: 4px;

    .title {
      margin-left: 4px;
      color: $secondary-color-00;
      font-size: 14px;
      line-height: 20px;
    }

    .icon {
      margin-left: 4px;
      width: 16px;
      height: 16px;
      margin-top: -2px;
      cursor: pointer;
    }
  }
}

@media (max-width: $desktop-width) {
  .container {
    width: 100%;

    .filtersButton {
      display: block;
      width: 158px;
    }
  }

  .sticky {
    max-height: 100vh;
  }

  .backdrop {
    display: none;
    background-color: #48555e47;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &.show {
      display: block;
    }

    .wrapper {
      width: calc(100% - (16px * 2));
      max-width: calc(318px + (16px * 2));
      padding: 16px;
      margin: 0 auto;

      .modalHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        .modalHeaderText {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          color: $secondary-color-00;
        }

        .modalHeaderIcon {
          width: 24px;
          height: 24px;
          position: relative;
          cursor: pointer;
        }
      }
    }

    .filter {
      &.filtersList {
        min-height: 0;
      }
    }
  }
}

@media (max-width: $under-tablet-width) {
  .container {
    .filtersButton {
      width: 100%;
    }
  }

  .sticky {
    max-height: 100vh;
  }
}
